import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'antd/lib/button/style/css';
import 'antd/lib/table/style/css';
import 'antd/lib/select/style/css';
import 'antd/lib/input-number/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/modal/style/css';
import 'antd/lib/notification/style/css';
import 'react-tabs/style/react-tabs.css';
import 'react-phone-number-input/style.css';
import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));
