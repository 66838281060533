import React, {Component} from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {Flex} from 'grid-styled';
import ListOfItems from './components/ListOfItems';
import header from './header.png';
import styled from 'styled-components';
import loadable from 'react-loadable';

document.getElementById('no-script').style.display = 'none';

const AdminPanel = loadable({
	loader: () => import('./components/AdminPanel'),
	loading: () => <div />
});
const createBrowserHistory = require('history').createBrowserHistory;

const history = createBrowserHistory();

class App extends Component {
	render() {
		return (
			<StyledWrapper flexDirection='column'>
				<img src={header} alt='Шины и диски, в наличии и под заказ, по низким ценам Автоботы74'
					style={{mixBlendMode: 'multiply'}} />
				<StyledBody flex={1}>
					<Router history={history}>
						<Switch>
							<Route exact path='/admin' component={AdminPanel} />
							<Route path='/:path' render={({match: {params: {path}}}) => {
								return (
									<ListOfItems path={`${path}`} />
								);
							}} />
							<Redirect to='/tiers' />
						</Switch>
					</Router>
				</StyledBody>
			</StyledWrapper>
		);
	}
}

export default App;
export {history};

const StyledWrapper = styled(Flex)`
	flex: 1;
	padding-left: 10px;
	padding-right: 10px;

	img {
		margin: 0 auto 0 auto;
	}
`;

const StyledBody = styled(Flex)`
	padding-top: 5px;
	margin: 0 auto 0 auto;
`;
