import axios from 'axios';

const getGlobalData = async () => {
	return axios.get('/api/global-data?admin=1')
		.then(response => {
			return response.data;
		})
		.catch(error => {
			console.log(error);
		});
};

export default getGlobalData;
