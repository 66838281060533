import axios from 'axios';

const sendOrder = async data => {
	await axios.post('/api/send-order', data)
		.then(response => {
			return response;
		})
		.catch(error => {
			console.log(error);
		});
};

export default sendOrder;
