import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Input, InputNumber, Modal, notification} from 'antd';
import {Flex} from 'grid-styled';
import {Link, Route} from 'react-router-dom';
import numeral from 'numeral';
import PhoneInput from 'react-phone-number-input/basic-input';
import _ from 'lodash';
import {history} from '../App';
import styled from 'styled-components';
import declOfNum from '../functions/declOfNum';
import sendOrder from '../axios/sendOrder';
import loadable from 'react-loadable';

const {TextArea} = Input;
const Icon = loadable({
	loader: () => import('antd/lib/icon'),
	loading: () => <div></div>
});

class Cart extends Component {
	static propTypes = {
		items: PropTypes.array.isRequired,
		changeCart: PropTypes.func,
		removeCart: PropTypes.func,
		clearCart: PropTypes.func,
	};

	state = {
		contact: '',
		phone: '8'
	};

	onCancelModal(e) {
		e.stopPropagation();
		history.length > 2 ? history.goBack() : history.push('/');
	}

	async onOkModal(items, contact, phone) {
		await sendOrder({items, contact, phone});
		history.length > 2 ? history.goBack() : history.push('/');
		this.props.clearCart();
		notification.success({
			message: 'Заказ оформлен',
			description:
				`В скором времени с вами свяжутся, по номеру ${phone}.`,
			duration: 0,
		});
	}

	render() {
		let {items} = this.props;
		const {contact, phone} = this.state;

		items = _.compact(items);

		const allSum = _.reduce(items, (sum, rec) => sum + (rec.cartCount * rec.price), 0);

		return (
			<StyledCart className={'cart'}>
				<Link to={{pathname: '/cart/items'}}>
					<Flex>
						<Flex flexDirection='column'>
							<Flex>
								Корзина
							</Flex>
							<Flex>
								{
									items.length === 0 ? 'пуста' :
										items.length + ' ' + declOfNum(items.length, ['товар', 'товара', 'товаров'])
								}
							</Flex>
						</Flex>
						<Flex className={'cart-icon-wrapper'}>
							<Icon type='shopping-cart' />
						</Flex>
					</Flex>
				</Link>
				<Route path='/cart' render={() => {
					return (
						<Modal
							title='Корзина'
							visible
							centered
							onOk={async () => this.onOkModal(items, contact, phone)}
							onCancel={this.onCancelModal}
							okText={'Оформить заказ'}
							cancelText={'назад'}
							okButtonProps={{disabled: items.length === 0 || phone.length < 11}}
							style={{minWidth: 600}}
						>
							<Flex flexDirection={'column'}>
								{items.length > 0 ? items.map((obj, key) => (
									<StyledDescription key={key} className={'tier-description'} flexDirection={'column'}>
										<Flex>{obj.brand} {obj.name}</Flex>
										<Flex>
											<StyledImgWrapper>
												<img
													src={(obj.img_small || '').replace('#', obj.bolts_count ? 'http://4tochki.ru/pictures/wheels' : 'http://4tochki.ru/pictures/tyres')}
													alt=' ' style={{mixBlendMode: 'multiply'}} />
											</StyledImgWrapper>
											<StyledFlex flexDirection={'column'}>
												<Flex>
													{obj.season || ''}
												</Flex>
												<StyledPrice className={'tier-price'}>
													<InputNumber
														defaultValue={obj['cartCount']}
														className='count-input'
														size='large'
														onChange={value => {
															obj.cartCount = value;
															this.props.changeCart(obj, key);
														}}
														min={1}
													/>
													{'х ' + numeral(obj.price).format('0,0')} руб.
													<Button
														className='remove-button'
														icon={'delete'}
														type={'danger'}
														shape='circle'
														onClick={() => {
															this.props.removeCart(obj, key);
														}}
													/>
												</StyledPrice>
												<StoreFlex>
													{obj.store === 'chelyab' ? `${obj.count} шт. на складе` : 'под заказ'}
												</StoreFlex>
											</StyledFlex>
										</Flex>
									</StyledDescription>
								)) : 'нет товара'}
								{items.length > 0 &&
								<StyledContact flexDirection={'column'}>
									<Flex className='tier-price'>
										Итого стоимость: {numeral(allSum).format('0,0')} руб.
									</Flex>
									Телефон:
									<PhoneInput
										country={'RU'}
										value={phone}
										onChange={phone => {
											if (phone.length >= 1 && phone.length <= 11) {
												if (phone[0] !== '8') {
													phone = '8' + phone;
												}
												this.setState({phone});
											}
											if (phone.length === 0) {
												this.setState({phone: '8'});
											}
										}
										}
									/>
									<Flex className='contact-description' />
									Примечание:
									<TextArea onChange={e => this.setState({contact: e.target.value})} rows={4} />
								</StyledContact>
								}
							</Flex>
						</Modal>
					);
				}} />
			</StyledCart>
		);
	}
}

export default Cart;

const StyledCart = styled(Flex)`
	a {
		color: black;

		:hover {
			color: #40a9ff;
		}
	}

	padding: 20px 20px 10px 0;

	.cart-icon-wrapper {
		padding-left: 20px;
	}

	cursor: pointer;
	margin-bottom: 5px;

	svg {
		font-size: 54px;
	}
`;

const StyledDescription = styled(Flex)`
	min-height: 120px;
	width: 550px;
	margin-bottom: 17px;
`;

const StyledFlex = styled(Flex)`
	flex: 1;
`;

const StyledPrice = styled(Flex)`
	align-items: center;
	justify-content: flex-end;
	flex: 1;

	.remove-button {
		margin-left: 10px;
	}

	.count-input {
		width: 90px;
		margin-right: 10px;
	}
`;

const StyledImgWrapper = styled(Flex)`
	min-height: 120px;
	min-width: 120px;
	align-items: center;
	justify-content: center;
`;

const StyledContact = styled(Flex)`
	padding-top: 20px;
	font-size: 16px;

	.contact-description {
		padding-top: 15px;
		flex: 1;
	}
`;

const StoreFlex = styled(Flex)`
	justify-content: flex-end;
`;
