import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'antd';
import {Flex} from 'grid-styled';
import {Link, Route} from 'react-router-dom';
import parse from 'html-react-parser';
import {history} from '../App';
import styled from 'styled-components';

class Delivery extends Component {
	static propTypes = {
		deliveryCaption: PropTypes.string.isRequired,
	};

	onCancelModal(e) {
		e.stopPropagation();
		history.length > 2 ? history.goBack() : history.push('/');
	}

	render() {
		return (
			<StyledDeliveryFlex>
				<Button className='delivery-modal-btn'>
					<Link to={{pathname: '/delivery/description'}}>О доставке</Link>
				</Button>

				<Route path='/delivery' render={() => {
					return (
						<Modal
							wrapClassName={'delivery-modal'}
							title='Доставка'
							visible
							centered
							style={{minWidth: 600}}
							onCancel={this.onCancelModal}
							cancelText={'назад'}
							okButtonProps={{hidden: true}}
						>
							<Flex>
								{parse(this.props.deliveryCaption)}
							</Flex>
						</Modal>
					);
				}} />
			</StyledDeliveryFlex>
		);
	}
}

export default Delivery;

const StyledDeliveryFlex = styled(Flex)`
	display: block;
`;
