import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'antd';
import {Flex} from 'grid-styled';
import {history} from '../App';

class ImageModal extends Component {
	static propTypes = {
		imageModalData: PropTypes.object.isRequired,
		backPath: PropTypes.string.isRequired,
	};

	onCancelModal(e) {
		e.stopPropagation();
		history.push(this.props.backPath);
	}

	render() {
		const {name, brand, img} = this.props.imageModalData;

		return (
			<Modal
				wrapClassName={'image-modal'}
				title={
					<Flex flexDirection={'column'}>
						<Flex>{brand}</Flex>
						<Flex>{name}</Flex>
					</Flex>
				}
				visible
				centered
				onCancel={e => this.onCancelModal(e)}
				okButtonProps={{hidden: true}}
				cancelButtonProps={{hidden: true}}
			>
				<img src={img} alt=' ' />
			</Modal>
		);
	}
}

export default ImageModal;
