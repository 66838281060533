import axios from 'axios';

const getRimsList = async () => {
	return axios.get('/api/rims-list')
		.then(response => {
			return response.data;
		})
		.catch(error => {
			console.log(error);
		});
};

export default getRimsList;
