import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, InputNumber, Select, Table} from 'antd';
import {Flex} from 'grid-styled';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import getTiersList from '../axios/getTiersList';
import getRimsList from '../axios/getRimsList';
import getGlobalData from '../axios/getGlobalData';
import styled from 'styled-components';
import ScrollUpButton from 'react-scroll-up-button';
import parse from 'html-react-parser';
import _ from 'lodash';
import numeral from 'numeral';
import Cart from './Cart';
import Delivery from './Delivery';
import ImageModal from './ImageModal';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {history} from '../App';

const {Option} = Select;

class ListOfItems extends Component {
	static propTypes = {
		path: PropTypes.string.isRequired
	};

	state = {
		columns: [
			{
				key: 'id',
				render: obj => {
					return (
						<StyledDescription className={'tier-description'} flexDirection={'column'}>
							<Flex>{obj.brand} {obj.name}</Flex>
							<Flex>
								<StyledImgWrapper>
									{obj.img ?
										<Link to={{pathname: `${this.state.paginatorPage}/image/${obj.id}/${obj.price}`}}
											onClick={() => this.setState({
												imageModalData: {
													...obj,
													img: (obj.img || '').replace('#', 'http://api-b2b.pwrs.ru')
												}
											})}>
											<img
												src={(obj.img_small || '').replace('#', obj.bolts_count ? 'http://4tochki.ru/pictures/wheels' : 'http://4tochki.ru/pictures/tyres')}
												alt=' '
												style={{mixBlendMode: 'multiply'}}
											/>
										</Link> : ''
									}
								</StyledImgWrapper>
								<StyledFlex flexDirection={'column'}>
									<Flex>
										{obj.season || ''}&nbsp;
									</Flex>
									<StyledPrice className={'tier-price'}>
										{!obj['inCart'] &&
										<InputNumber
											defaultValue={4}
											onChange={value => obj.cartCount = value}
											className='count-input'
											size='large'
											min={1}
										/>
										}
										{(obj['inCart'] ? `${obj['cartCount']} шт. ` : '') + 'х ' + numeral(obj.price).format('0,0')} руб.
										<Button
											className='add-button'
											icon={!obj['inCart'] ? 'shopping-cart' : 'check'}
											type={!obj['inCart'] ? 'primary' : 'default'}
											disabled={obj['inCart']}
											shape='circle'
											onClick={() => this.addCart(obj)}
										/>
									</StyledPrice>
									<StoreFlex>
										{obj.store === 'chelyab' ? `${obj.count} шт. на складе` : 'под заказ'}
									</StoreFlex>
								</StyledFlex>
							</Flex>
						</StyledDescription>
					);
				}
			}
		],
		tiers: [],
		tiersFiltersData: {},
		tierFilters: {},
		rims: [],
		rimsFiltersData: {},
		rimFilters: {},
		addToCart: [],
		isLoad: false,
		isLoadRims: false,
		captions: {},
		imageModalData: {},
		next_update_at: '',
		paginatorPage: 1
	};

	async componentDidMount() {
		const addToCart = JSON.parse(localStorage.getItem('local-cart')) || [];
		const lastUpdateDate = localStorage.getItem('local-last-update') || '';

		const {next_update_at, captions} = await getGlobalData();

		this.setState({
			captions,
			next_update_at
		});

		if (this.props.path === 'rims') {
			await this.loadRims(addToCart, lastUpdateDate, next_update_at);
			this.loadTiers(addToCart, lastUpdateDate, next_update_at);
		} else {
			await this.loadTiers(addToCart, lastUpdateDate, next_update_at);
			this.loadRims(addToCart, lastUpdateDate, next_update_at);
		}
	}

	async loadRims(addToCart, lastUpdateDate, next_update_at) {
		const {rims} = await getRimsList();

		if (lastUpdateDate === next_update_at) {
			// eslint-disable-next-line
			for (const cartItm of addToCart) {
				const rim = rims.find(rec => rec.id === cartItm.id);

				if (rim) {
					rim['inCart'] = true;
					rim['cartCount'] = cartItm['cartCount'];
				}
			}
		} else {
			addToCart = [];
		}

		const getArr = name => _.chain(rims)
			.uniqBy(name)
			.map(rec => ({
				value: rec[name]
			}))
			.sortBy(['value'])
			.value();

		this.setState({
			rims,
			rimsFiltersData: {
				width: getArr('width'),
				et: getArr('et'),
				dia: getArr('dia'),
				bolts_spacing: getArr('bolts_spacing'),
				diameter: getArr('diameter'),
				bolts_count: getArr('bolts_count'),
				brand: getArr('brand')
			},
			addToCart,
			isLoadRims: true
		});
	}

	async loadTiers(addToCart, lastUpdateDate, next_update_at) {
		const {tiers} = await getTiersList();

		if (lastUpdateDate === next_update_at) {
			// eslint-disable-next-line
			for (const cartItm of addToCart) {
				const tier = tiers.find(rec => rec.id === cartItm.id);

				if (tier) {
					tier['inCart'] = true;
					tier['cartCount'] = cartItm['cartCount'];
				}
			}
		} else {
			addToCart = [];
		}

		const getArr = name => _.chain(tiers)
			.uniqBy(name)
			.map(rec => ({
				value: rec[name]
			}))
			.sortBy(['value'])
			.value();

		this.setState({
			tiers,
			tiersFiltersData: {
				brands: getArr('brand'),
				widths: getArr('width'),
				heights: getArr('height'),
				diametersInt: getArr('diameterInt')
			},
			addToCart,
			isLoad: true
		});
	}

	addCart(obj) {
		let addToCart = this.state.addToCart;
		obj['inCart'] = true;
		obj['cartCount'] = !obj['cartCount'] ? 4 : obj['cartCount'];
		addToCart.push(obj);

		addToCart = _.compact(addToCart);

		this.setState({addToCart});

		localStorage.setItem('local-cart', JSON.stringify(this.state.addToCart));
		localStorage.setItem('local-last-update', this.state.next_update_at);
	}

	changeCart(obj, objKey) {
		const addToCart = this.state.addToCart;
		const tiers = this.state.tiers;
		const rims = this.state.rims;

		if (!obj.bolts_count) {
			const tier = tiers.find(rec => rec.id === obj.id);

			tier['cartCount'] = obj.cartCount;
		} else {
			const rim = rims.find(rec => rec.id === obj.id);

			rim['cartCount'] = obj.cartCount;
		}

		addToCart[objKey] = obj;

		this.setState({addToCart, tiers, rims});

		localStorage.setItem('local-cart', JSON.stringify(addToCart));
	}

	removeCart(obj, objKey) {
		let addToCart = this.state.addToCart;
		const tiers = this.state.tiers;
		const rims = this.state.rims;

		if (!obj.bolts_count) {
			const tier = tiers.find(rec => rec.id === obj.id);

			tier['inCart'] = undefined;
			tier['cartCount'] = undefined;
		} else {
			const rim = rims.find(rec => rec.id === obj.id);

			rim['inCart'] = undefined;
			rim['cartCount'] = undefined;
		}

		Reflect.deleteProperty(addToCart, objKey);
		addToCart = _.compact(addToCart);

		this.setState({addToCart, tiers, rims});

		localStorage.setItem('local-cart', JSON.stringify(addToCart));
	}

	clearCart() {
		this.setState({addToCart: []});

		localStorage.setItem('local-cart', JSON.stringify([]));
	}

	getDataSource(filters, table) {
		return (this.state[table] || []).filter(rec => {
			let isFilter = true;

			_.find(filters, (filterRec, key) => {
				if (filterRec && typeof filterRec === 'boolean') {
					switch (key) {
						case 'seasonAll':
							isFilter = rec['season'] === 'Всесезонная';
							break;
						case 'seasonSummer':
							isFilter = rec['season'] === 'Летняя';
							break;
						case 'seasonWinter':
							isFilter = rec['season'] === 'Зимняя';
							break;
						case 'thorn':
							isFilter = rec['thorn'];
							break;
						case 'runflat':
							isFilter = rec['runflat'];
							break;
						default:
							break;
					}

					return !isFilter;
				}
				if (filterRec || filterRec === 0) {
					isFilter = filterRec === rec[key];

					return !isFilter;
				}
			});

			return isFilter;
		});
	}

	renderSelect(arr, name, filterName, disabled) {
		return (
			<Select
				onChange={value => {
					const newState = this.state;

					if (value || value === 0) {
						newState[filterName][name] = value;
					} else {
						Reflect.deleteProperty(newState[filterName], name);
					}

					this.setState(newState);
				}}
				disabled={disabled}
				className={'filter-select'}
				allowClear
				value={this.state[filterName][name]}
				maxTagCount={3}
			>
				{arr.map(obj => <Option key={obj.value} value={obj.value}>{obj.value}</Option>)}
			</Select>
		);
	}

	renderCheckbox(text, name, filterName, disabled, isNotSeason) {
		return (
			<Checkbox
				onChange={e => {
					const newState = this.state;
					const value = e.target.checked;


					if (value) {
						newState[filterName][name] = e.target.checked;

						if (!isNotSeason) {
							newState[filterName]['season'] = true;
						}
					} else {
						Reflect.deleteProperty(newState[filterName], name);

						if (!isNotSeason) {
							Reflect.deleteProperty(newState[filterName], 'season');
						}
					}

					this.setState(newState);
				}}
				disabled={(disabled || isNotSeason || this.state[filterName][name]) ? false : this.state[filterName].season}
				className={'filter-checkbox'}
				checked={this.state[filterName][name]}
			>
				{text}
			</Checkbox>
		);
	}

	renderCart(captions, addToCart) {
		return (
			<CartFlex flexDirection={'column'}>
				<Delivery deliveryCaption={captions.delivery_caption || ''} />
				<Cart items={addToCart} changeCart={this.changeCart.bind(this)} removeCart={this.removeCart.bind(this)}
					clearCart={this.clearCart.bind(this)} />
			</CartFlex>
		);
	}

	paginatorItemRender(page, type, path) {
		let str;

		switch (type) {
			case 'prev':
				str = '<';
				break;
			case 'next':
				str = '>';
				break;
			case 'jump-prev':
				str = '<<';
				break;
			case 'jump-next':
				str = '>>';
				break;
			default:
				str = page;
		}
		return (
			<Link to={{pathname: `/${path}/${page}`}}>
				{str}
			</Link>
		);
	}

	renderTabs(num) {
		const {path} = this.props;

		const {
			tierFilters,
			tiersFiltersData,
			rimFilters,
			rimsFiltersData,
			captions = {},
			columns,
			addToCart,
			isLoad,
			isLoadRims
		} = this.state;

		return (
			<Tabs defaultIndex={path === 'rims' ? 1 : 0}>
				<TabList>
					<Tab><Link to={{pathname: '/tiers'}}>Шины</Link></Tab>
					<Tab><Link to={{pathname: '/rims'}}>Диски</Link></Tab>
				</TabList>
				<TabPanel>{/*ШИНЫ*/}
					<StyledTableWrapper flexDirection='column'>
						<Flex>
							<Table
								className={'table'}
								rowKey={'id'}
								columns={columns}
								dataSource={this.getDataSource(tierFilters, 'tiers')}
								size='default'
								loading={!isLoad}
								pagination={{
									defaultCurrent: +num,
									pageSize: 12,
									position: 'top',
									itemRender: (page, type) => this.paginatorItemRender(page, type, path),
									onChange: page => this.setState({paginatorPage: page})
								}}
								locale={{
									filterConfirm: 'Ок',
									filterReset: 'Сбросить',
									emptyText: 'Нет данных'
								}}
							/>
							<Flex className={'right-sidebar'} flexDirection={'column'}>
								{this.renderCart(captions, addToCart)}
								Ширина
								{this.renderSelect(tiersFiltersData.widths || [], 'width', 'tierFilters', !isLoad)}
								Профиль
								{this.renderSelect(tiersFiltersData.heights || [], 'height', 'tierFilters', !isLoad)}
								Диаметр
								{this.renderSelect(tiersFiltersData.diametersInt || [], 'diameterInt', 'tierFilters', !isLoad)}
								Производитель
								{this.renderSelect(tiersFiltersData.brands || [], 'brand', 'tierFilters', !isLoad)}
								<StyledChk>
									{this.renderCheckbox('Всесезонные', 'seasonAll', 'tierFilters', !isLoad)}
									{this.renderCheckbox('Летние', 'seasonSummer', 'tierFilters', !isLoad)}
									{this.renderCheckbox('Зимние', 'seasonWinter', 'tierFilters', !isLoad)}
									{this.renderCheckbox('Зимние шипы', 'thorn', 'tierFilters', !isLoad)}
									{this.renderCheckbox('Run flat', 'runflat', 'tierFilters', !isLoad, true)}
								</StyledChk>
								<Flex className={'reset-filter'}>
									<Button
										type={_.size(tierFilters) > 0 ? 'primary' : 'default'}
										disabled={_.size(tierFilters) === 0}
										onClick={() => this.setState({tierFilters: {}})}
									>
										Сбросить фильтр
									</Button>
								</Flex>
							</Flex>
						</Flex>
					</StyledTableWrapper>
				</TabPanel>
				<TabPanel>{/*ДИСКИ*/}
					<StyledTableWrapper flexDirection='column'>
						<Flex>
							<Table
								className={'table'}
								rowKey={'id'}
								columns={columns}
								dataSource={this.getDataSource(rimFilters, 'rims')}
								size='default'
								loading={!isLoadRims}
								pagination={{
									defaultCurrent: +num,
									pageSize: 12,
									position: 'top',
									itemRender: (page, type) => this.paginatorItemRender(page, type, path),
									onChange: page => this.setState({paginatorPage: page})
								}}
								locale={{
									filterConfirm: 'Ок',
									filterReset: 'Сбросить',
									emptyText: 'Нет данных'
								}}
							/>
							<Flex className={'right-sidebar'} flexDirection={'column'}>
								{this.renderCart(captions, addToCart)}
								Ширина
								{this.renderSelect(rimsFiltersData.width || [], 'width', 'rimFilters', !isLoadRims)}
								Диаметр
								{this.renderSelect(rimsFiltersData.diameter || [], 'diameter', 'rimFilters', !isLoadRims)}
								Кол-во крепежных отверстий
								{this.renderSelect(rimsFiltersData.bolts_count || [], 'bolts_count', 'rimFilters', !isLoadRims)}
								Диаметр крепежных отверстий
								{this.renderSelect(rimsFiltersData.bolts_spacing || [], 'bolts_spacing', 'rimFilters', !isLoadRims)}
								Вылет
								{this.renderSelect(rimsFiltersData.et || [], 'et', 'rimFilters', !isLoadRims)}
								Ступица
								{this.renderSelect(rimsFiltersData.dia || [], 'dia', 'rimFilters', !isLoadRims)}
								Производитель
								{this.renderSelect(rimsFiltersData.brand || [], 'brand', 'rimFilters', !isLoadRims)}
								<Flex className={'reset-filter'}>
									<Button
										type={_.size(rimFilters) > 0 ? 'primary' : 'default'}
										disabled={_.size(rimFilters) === 0}
										onClick={() => this.setState({rimFilters: {}})}
									>
										Сбросить фильтр
									</Button>
								</Flex>
							</Flex>
						</Flex>
					</StyledTableWrapper>
				</TabPanel>
			</Tabs>
		);
	}

	render() {
		const {captions = {}, imageModalData, isLoad, isLoadRims} = this.state;
		const {path} = this.props;

		return (
			<StyledTabsWrapper flexDirection={'column'}>
				<PhoneFlex type='text'>
					<Flex className='phone-bkg'>
						{parse(captions.phones || '')}
					</Flex>
				</PhoneFlex>
				<Flex className='info-block'>
					{parse(captions.info_caption || '')}
				</Flex>
				<Switch>
					<Route path='/:path/:num' render={({match: {params: {num}}}) => {
						return this.renderTabs(num);
					}} />
					<Redirect to={'/:path/1'} />
				</Switch>
				<Flex className='info-block-footer'>
					{parse(captions.footer_caption || '')}
				</Flex>
				<Route path='/:path' render={({match: {params: {path}}}) => {
					if (path === 'rims' || path === 'tiers') {
						return (
							<ScrollUpButton
								StopPosition={0}
								ShowAtPosition={300}
								EasingType='easeOutCubic'
								AnimationDuration={200}
							/>
						);
					}
				}} />
				{((path === 'rims' && isLoadRims) || (path === 'tiers' && isLoad)) &&
				<Route path='/:path/:num/image/:id/:price' exact render={({match: {params: {price, id, path, num}}}) => {
					let find = imageModalData;

					if (!find.img) {
						find = this.state[path].find(rec => rec.id === +id && rec.price === +price) || {};

						if (!find.img) {
							history.push(`/${path}/${num}`);
						}
					}
					return (
						<ImageModal
							imageModalData={{...find, img: (find.img || '').replace('#', 'http://api-b2b.pwrs.ru')}}
							backPath={`/${path}/${num}`}
						/>
					);
				}} />
				}
			</StyledTabsWrapper>
		);
	}
}

export default ListOfItems;

const StyledTableWrapper = styled(Flex)`
	flex: 1;

	.ant-table-thead {
		display: none;
	}

	.table {
		min-width: 540px;
		min-height: 700px;
	}

	.right-sidebar {
		padding: 5px 0 0 15px;

		.filter-select {
			padding-bottom: 5px;
			width: 300px;
		}
	}

	.reset-filter {
		padding-top: 20px;
		padding-left: 150px;
	}
`;

const StyledDescription = styled(Flex)`
	min-height: 120px;
	width: 500px;
`;

const StyledFlex = styled(Flex)`
	flex: 1;
`;

const StyledTabsWrapper = styled(Flex)`
	align-items: center;

	.info-block {
		display: block;
		width: 860px;
		padding: 0 10px 0 10px;

		span {
			color: black !important;
		}
	}

	.info-block-footer {
		display: block;
		max-width: 1200px;
		padding: 25px 10px 0 10px;

		span {
			color: black !important;
		}
	}

	.react-tabs__tab {
		font-size: 30px;
		padding: 0 0 0 0;

		a {
			color: black;
			padding: 6px 30px 6px 30px;

			:hover {
				color: #40a9ff;
			}
		}
	}
`;

const StoreFlex = styled(Flex)`
	justify-content: flex-end;
`;

const StyledPrice = styled(Flex)`
	padding-top: 9px;
	justify-content: flex-end;
	flex: 1;

	.add-button {
		margin-left: 10px;
	}

	.count-input {
		width: 90px;
		margin-right: 10px;
	}
`;

const StyledImgWrapper = styled(Flex)`
	min-height: 120px;
	min-width: 120px;
	align-items: center;
	justify-content: center;
`;

const StyledChk = styled(Flex)`
	flex-direction: column;
	padding-top: 15px;

	.filter-checkbox {
		padding-top: 6px;
		margin-left: 8px;
		margin-right: 8px;
	}
`;

const PhoneFlex = styled(Flex)`
	justify-content: center;
	flex: 1;
	padding: 5px 0 20px 0;
	margin-top: -65px;

	.phone-bkg {
		text-shadow: 2px 2px black;
		margin-left: 120px;
		background-color: rgba(233, 150, 122, 0.6);
		padding: 0 15px 0 15px;
		z-index: 1000;
		font-size: 30px;
		min-height: 45px;

		a {
			color: rgba(255, 255, 255, 1) !important;
		}

		border-radius: 6px;
		user-select: text !important;
	}
`;

const CartFlex = styled(Flex)`
	align-items: flex-end;
`;
